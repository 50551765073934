<div class="flex flex-col justify-center items-center bg-gradient-to-r from-blue-900 via-blue-500 to-blue-900 h-screen">
  <div class="bg-white rounded-xl p-10">
    <div fxLayout="column" fxLayoutAlign="center center" class="min-h-full" *ngIf="parametrosLogin">
      <!-- LOGO EN RESPONSIVE -->
      <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxShow.lt-md fxHide.gt-sm>
        <div fxFlex fxFlexOffset="15">
          <!-- <img src="/assets/images/familiar_logo.png" alt="Logo Banco Familiar"> -->
        </div>
      </div>
      <!-- END  LOGO EN RESPONSIVE -->
      <ng-template [ngIf]="show === 'inicio'">
        <div class="w-96">
          <form class="w-32" fxFlex fxLayout="column" [formGroup]="loginForm">
            <div fxFlex fxLayoutAlign="center center" fxHide.lt-md fxShow.gt-sm>
              <!-- <img class="h-52" src="/assets/images/isotipo_familiar.png" alt="Logo Banco Familiar"> -->
            </div>
            <p class="uppercase text-2xl font-bold text-center my-3">Iniciar sesión</p>
            <mat-form-field appearance="outline" class="mb-3 mt-3">
              <mat-label *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'email'">Ingrese su email</mat-label>
              <mat-label *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'username'">
                Ingrese su nombre de usuario
              </mat-label>
              <input type="text" matInput formControlName="username" required autocomplete="username">
              <mat-hint *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'email'"> Nunca compartiremos su correo
                electrónico
                con nadie más.
              </mat-hint>
              <mat-hint *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'doc'">
                Nunca compartiremos su información con nadie más.
              </mat-hint>
              <mat-error
                *ngIf="loginForm.controls.username.hasError('email') && !loginForm.controls.username.hasError('required')">
                Por favor, ingrese una dirección de email valida.
              </mat-error>
              <mat-error
                *ngIf="loginForm.controls.username.hasError('required') && parametrosLogin.LOGINTYPE.LOGINTYPE === 'email'">
                El email es <strong>requerido</strong>
              </mat-error>
              <mat-error
                *ngIf="loginForm.controls.username.hasError('required') && parametrosLogin.LOGINTYPE.LOGINTYPE === 'doc'">
                El número de documento es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Contraseña</mat-label>
              <input #passInput type="password" matInput autocomplete="current-password" formControlName="password"
                id="password">
              <mat-icon *ngIf="passInput.type === 'password'" style="cursor: pointer;" matSuffix
                (click)="onEyePasswordClick()">
                visibility
              </mat-icon>
              <mat-icon *ngIf="passInput.type === 'text'" style="cursor: pointer;" matSuffix
                (click)="onEyePasswordClick()">
                visibility_off
              </mat-icon>
            </mat-form-field>
            <div fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="center">
              <button class="rounded-xl hover:bg-blue-700" mat-raised-button type="submit" color="primary"
                (click)="iniciarSesion()" [disabled]="!loginForm.valid">
                <mat-icon>login</mat-icon> Ingresar
              </button>
              <div fxLayout="row" fxLayoutAlign="center center">
                <button class="rounded-xl hover:bg-yellow-800" mat-button type="button" (click)="changeVisual('forgot')"
                  class="bg-yellow-400 rounded-full p-2 text-white">
                  <mat-icon class="mr-2">key_off</mat-icon>Olvidé mi contraseña
                </button>
              </div>
              <!-- NO SE APLICA EL REGISTRO PARA BANCO FAMILIAR -->
              <!-- <button mat-button type="button" (click)="changeVisual('register')"
                    style="background: rgba(221, 241, 253, 1); border-radius: 12px; color:rgba(7, 71, 135, 1); white-space: normal">
                    ¿No eres usuario? <b>Regístrate aquí</b></button> -->
            </div>
          </form>
        </div>
      </ng-template>
      <ng-template [ngIf]="show === 'register'">
        <div fxFlex fxFlexOffset.gt-sm="5%">
          <h2>Regístrate</h2>
        </div>
        <app-register [minpassword]="parametrosLogin.MINPASSWORD.MINPASSWORD" (hide)="changeVisual('inicio')"
          [maxpassword]="parametrosLogin.MAXPASSWORD.MAXPASSWORD"></app-register>
      </ng-template>
      <ng-template [ngIf]="show === 'forgot'">
        <div class="text-center">
          <h2 class="uppercase text-2xl font-sans font-extrabold">Recuperar la cuenta</h2>
        </div>
        <app-olvidar-contrasenha (hide)="changeVisual('inicio')" [loginType]="parametrosLogin.LOGINTYPE.LOGINTYPE">
        </app-olvidar-contrasenha>
      </ng-template>
      <ng-template [ngIf]="show === 'recover'">
        <div fxFlex fxFlexOffset.gt-sm="20%">
          <h2>Cambiar contraseña</h2>
        </div>
        <app-recuperar-contrasenha (hide)="changeVisual('inicio')">
        </app-recuperar-contrasenha>
      </ng-template>
    </div>
  </div>
  <div class="flex self-center">
    <img src="https://familiar.espy.cloud/assets/images/enterprise_white_logo.png"
      class="my-5 self-center w-62 h-20 -ml-12" />
    <img src="https://familiar.espy.cloud/assets/images/logo_bix.png" class="my-5 self-center w-20 h-20" />
  </div>
</div>

<div *ngIf="isLoading$ | async" class="loading-container">
  <mat-progress-spinner class="spinner" color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>