import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormStatusService } from '../../util/services/form-status/form-status.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '../../util/services/error-service/error.service';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-forzar-cambio-contrasenha',
  templateUrl: './forzar-cambio-contrasenha.component.html',
  styleUrls: ['./forzar-cambio-contrasenha.component.sass']
})
export class ForzarCambioContrasenhaComponent implements OnInit {

  saveForm: FormGroup;

  constructor(private matDialog: MatDialogRef<ForzarCambioContrasenhaComponent>,
    @Inject(MAT_DIALOG_DATA) private usuario: any,
    private fS: FormStatusService, private fB: FormBuilder, private toastr: ToastrService,
    private errorService: ErrorService, private loginService: LoginService) {
  }

  ngOnInit(): void {
    this.makeForm();
  }

  makeForm(): void {
    this.saveForm = this.fB.group({
      password: [null, Validators.required],
      password_confirmation: [null, Validators.required]
    });
  }

  changePassword() {
    if (this.saveForm.get('password').value === this.saveForm.get('password_confirmation').value) {
      const jsonData = JSON.stringify(this.saveForm.value);
      this.loginService.changePassword(jsonData, this.usuario.userData.access_token)
        .subscribe((response: any) => {
          if (response.success) {
            this.toastr.success('Contraseña cambiada exitosamente!', 'Cambio de contraseña');
          }
        }, error => {
          this.errorService.handleError(error);
        }, () => {
          this.matDialog.close(true);
        });

    } else {
      this.toastr.error('Las contraseñas no coinciden.', 'Error: ');
    }
  }

}
