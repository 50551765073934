import { ReactiveFormsModule } from '@angular/forms';
import { BotoneraEdicionModule } from './../util/components/botonera-edicion/botonera-edicion.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseLayoutComponent } from './base-layout.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatLineModule} from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxPermissionsModule} from 'ngx-permissions';
import { PageTitleComponent } from './page-title/page-title.component';
import {RouterModule} from '@angular/router';
import { PageFilterComponent } from './page-filter/page-filter.component';
import { UserSettingsComponent } from './header/user-settings/user-settings.component';

@NgModule({
  declarations: [
    BaseLayoutComponent,
    PageTitleComponent,
    PageFilterComponent,
    UserSettingsComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatButtonModule,
    RouterModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatLineModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild(),
    BotoneraEdicionModule
  ],
  exports: [
    PageTitleComponent,
    PageFilterComponent
  ],
  bootstrap: [
    UserSettingsComponent
  ]

})
export class BaseLayoutModule { }
