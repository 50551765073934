<div fxLayout="row" fxLayoutGap="3%" fxLayoutAlign.gt-sm="center center" fxLayout.lt-md="column">
        <button type="button" (click)="editar()" class="rounded-lg px-6 py-3 bg-blue-500 text-white hover:bg-blue-700"
                *ngIf="editable" [disabled]="!showEditarButton">
                Editar
        </button>
        <button type="button" (click)="volver()" class="rounded-lg px-6 py-3 bg-gray-400 text-white hover:bg-gray-500"
                *ngIf="editable && !isModal">
                Volver
        </button>
        <button type="button" (click)="cerrar()" class="rounded-lg px-6 py-3 bg-red-500 text-white hover:bg-red-700"
                *ngIf="editable && isModal">
                Cerrar
        </button>
        <button type="button" (click)="actualizar()"
                class="rounded-lg px-6 py-3 bg-green-500 text-white hover:bg-green-700" [disabled]="process || !valid"
                *ngIf="!editable">
                {{buttonTextActualizar}}
        </button>
        <button type="button" (click)="cancelar()" class="rounded-lg px-6 py-3 bg-red-500 text-white hover:bg-red-700"
                *ngIf="!editable" [disabled]="process">
                Cancelar
        </button>
</div>