// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// @ts-ignore
import version from '../../package.json';
export const environment = {
  API_ENDPOINT_ES: 'https://apiportalreportes.espy.cloud/api/v2',
  FILE_ENDPOINT: 'https://apiportalreportes.espy.cloud',
  API_PBI: 'https://api.powerbi.com/v1.0',
  production: false,
  system_version: version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
